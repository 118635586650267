// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,
  // baseUrl: 'https://api.dev.herbtechkiosk.com',
  baseUrl: 'https://backend.herbtechkiosk.com',
  // baseUrl: 'https://dd90-2400-adc5-1c3-2800-b1df-460b-8587-38fe.ngrok.io/herbtech',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
