import { Component, Input, OnInit } from '@angular/core';
import {MerchantDetail} from '../../../../models/merchantDetail';

import {ConfigurationService} from '../../../../services/configuration.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() merchantDetail: MerchantDetail = new MerchantDetail();
  src: string;
  constructor(    private configurationService: ConfigurationService) { }

  ngOnInit(): void {
    if (this.merchantDetail.profile.backGroundImage.startsWith('htt')) {
      this.src = this.merchantDetail.profile.backGroundImage;
    } else {
      this.src = this.configurationService.baseUrl + this.merchantDetail.profile.backGroundImage;
    }
  }
}
