import {Component, ViewChild, ViewEncapsulation, OnInit} from '@angular/core';

import {NavigationService} from '../../../../services/navigation.service';
@Component({
  selector: 'app-qrpage-managment',
  templateUrl: './qrpage-managment.component.html',
  styleUrls: ['./qrpage-managment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QrpageManagmentComponent implements OnInit {
  constructor( private navigationService: NavigationService ) {

  }


  ngOnInit() {

  }
  onCodeResult(event: string) {
    if (event.startsWith('http')) {
      this.navigationService.navigateByUrl('/home/' + this.merchId(event));

    }
  }

  merchId(url: string) {
    let id: string;
    url.split('/').forEach( item => {
      if (item.length > 15 ) {
        id = item;
      }
    });
    return id;
  }

}
